import React, { useState, useRef, useEffect } from 'react';
import { Send, Mail } from 'lucide-react';
import emailjs from '@emailjs/browser';

// EmailJS configuration using environment variables
const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

const ContactPage = () => {
  const form = useRef();

  useEffect(() => {
    if (EMAILJS_PUBLIC_KEY) {
      emailjs.init(EMAILJS_PUBLIC_KEY);
    } else {
      console.error('EmailJS public key is missing');
    }
  }, []);

  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    subject: '',
    message: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.from_name.trim()) {
      errors.from_name = 'Full name is required';
    }
    if (!formData.from_email.trim()) {
      errors.from_email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.from_email)) {
      errors.from_email = 'Email address is invalid';
    }
    if (!formData.message.trim()) {
      errors.message = 'Message is required';
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      emailjs.sendForm(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, form.current, EMAILJS_PUBLIC_KEY)
        .then((result) => {
          console.log('Email sent result:', result.text);
          setSubmitMessage('Message sent successfully!');
          setFormData({ from_name: '', from_email: '', subject: '', message: '' });
        }, (error) => {
          console.error('Error sending email:', error.text);
          setSubmitMessage('Failed to send message. Please try again.');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (submitMessage) {
      const timer = setTimeout(() => setSubmitMessage(''), 5000);
      return () => clearTimeout(timer);
    }
  }, [submitMessage]);

  return (
    <div className="min-h-screen bg-white flex flex-col smooth-scroll overscroll-none no-bounce">
      {/* Main Content */}
      <main className="flex-grow container mx-auto px-4 pt-28 pb-20 flex flex-col md:flex-row items-center justify-center">
        <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            <span className="text-[#564EA5]">Let's chat.</span><br />
            <span className="text-[#5CC2C5]">Tell me about your project.</span>
          </h1>
          <p className="text-xl text-[#564EA5] mb-6">Let's create something together 👋</p>
          <div className="flex items-center text-[#564EA5]">
            <Mail size={20} className="mr-2" />
            <a href="mailto:rudy@marubelabs.com" className="hover:underline">rudy@marubelabs.com</a>
          </div>
        </div>
        <div className="md:w-1/2 bg-white p-8 rounded-lg">
          <h2 className="text-2xl font-semibold mb-6 flex items-center text-[#5CC2C5]">
            Send us a message
            <Send size={24} className="ml-2 text-[#5CC2C5]" />
          </h2>
          {submitMessage && (
            <div className={`mb-4 p-2 rounded ${submitMessage.includes('successfully') ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
              {submitMessage}
            </div>
          )}
          <form ref={form} onSubmit={handleSubmit}>
            <div className="mb-4">
              <input 
                type="text" 
                name="from_name"
                value={formData.from_name}
                onChange={handleChange}
                placeholder="Full name*" 
                className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#564EA5] text-[#564EA5] ${formErrors.from_name ? 'border-red-500' : 'border-gray-300'}`} 
                required 
              />
              {formErrors.from_name && <p className="text-red-500 text-xs mt-1">{formErrors.from_name}</p>}
            </div>
            <div className="mb-4">
              <input 
                type="email" 
                name="from_email"
                value={formData.from_email}
                onChange={handleChange}
                placeholder="Email address*" 
                className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#564EA5] text-[#564EA5] ${formErrors.from_email ? 'border-red-500' : 'border-gray-300'}`} 
                required 
              />
              {formErrors.from_email && <p className="text-red-500 text-xs mt-1">{formErrors.from_email}</p>}
            </div>
            <div className="mb-4">
              <input 
                type="text" 
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Subject" 
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#564EA5] text-[#564EA5]" 
              />
            </div>
            <div className="mb-6">
              <textarea 
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Tell us more about your project*" 
                rows="4" 
                className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#564EA5] text-[#564EA5] ${formErrors.message ? 'border-red-500' : 'border-gray-300'}`} 
                required
              ></textarea>
              {formErrors.message && <p className="text-red-500 text-xs mt-1">{formErrors.message}</p>}
            </div>
            <button 
              type="submit" 
              className="w-full bg-[#5CC2C5] text-[#F1FDFC] px-6 py-3 rounded-md hover:bg-[#F1FDFC] hover:text-[#5CC2C5] transition duration-300 disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Send message'}
            </button>
          </form>
        </div>
      </main>
    </div>
  );
};

export default ContactPage;