import React, { useState, useEffect } from 'react';
import { ArrowRight, Code, Users, Lightbulb, BookOpen, Calendar, Menu, X, DollarSign } from 'lucide-react';
import Lottie from 'react-lottie';
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import ContactPage from './ContactPage';
import animationData from '../src/Hero-Animation - 1726107990493.json';
import logo from './logo2.png';

const Navbar = ({ isSticky, openCalendly, scrollToSection }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavClick = (sectionId) => {
    if (isHomePage) {
      scrollToSection(sectionId);
    }
    setIsMenuOpen(false);
  };

  return (
    <header className={`bg-white fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isSticky ? 'py-2' : 'py-4'}`}>
      <div className="container mx-auto px-4 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src={logo} alt="Marube Labs Logo" className="h-8 w-auto" />
          <span className="text-2xl font-josefin font-medium text-[#564EA5] tracking-tight ml-2 flex items-center">Marube Labs</span>
        </Link>
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="text-[#564EA5] hover:text-[#897EFC]">
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        <nav className={`lg:flex ${isMenuOpen ? 'block' : 'hidden'} absolute lg:relative top-full left-0 w-full lg:w-auto bg-white lg:bg-transparent shadow-md lg:shadow-none z-40`}>
          <ul className="flex flex-col lg:flex-row lg:items-center space-y-2 lg:space-y-0 lg:space-x-6 p-4 lg:p-0">
            <li>
              {isHomePage ? (
                <a href="#services" onClick={() => handleNavClick('services')} className="text-[#564EA5] hover:text-[#897EFC] block lg:inline-block py-2 lg:py-0">Services</a>
              ) : (
                <Link to="/#services" className="text-[#564EA5] hover:text-[#897EFC] block lg:inline-block py-2 lg:py-0">Services</Link>
              )}
            </li>
            <li>
              {isHomePage ? (
                <a href="#products" onClick={() => handleNavClick('products')} className="text-[#564EA5] hover:text-[#897EFC] block lg:inline-block py-2 lg:py-0">Products</a>
              ) : (
                <Link to="/#products" className="text-[#564EA5] hover:text-[#897EFC] block lg:inline-block py-2 lg:py-0">Products</Link>
              )}
            </li>
            <li>
              {isHomePage ? (
                <a href="#education" onClick={() => handleNavClick('education')} className="text-[#564EA5] hover:text-[#897EFC] block lg:inline-block py-2 lg:py-0">Education</a>
              ) : (
                <Link to="/#education" className="text-[#564EA5] hover:text-[#897EFC] block lg:inline-block py-2 lg:py-0">Education</Link>
              )}
            </li>
            <li>
              {isHomePage ? (
                <a href="#pricing" onClick={() => handleNavClick('pricing')} className="text-[#564EA5] hover:text-[#897EFC] block lg:inline-block py-2 lg:py-0">Pricing</a>
              ) : (
                <Link to="/#pricing" className="text-[#564EA5] hover:text-[#897EFC] block lg:inline-block py-2 lg:py-0">Pricing</Link>
              )}
            </li>
            <li>
              <button 
                onClick={openCalendly}
                className="bg-[#5CC2C5] text-[#F1FDFC] px-4 py-2 rounded-md hover:bg-[#F1FDFC] hover:text-[#5CC2C5] flex items-center w-full lg:w-auto justify-center lg:justify-start"
              >
                <Calendar className="mr-2" size={20} />
                Book Free Consultation
              </button>
            </li>
            <li>
              <Link to="/contact" className="bg-[#F1FDFC] text-[#5CC2C5] px-4 py-2 rounded-md border-2 border-[#5CC2C5] hover:bg-[#5CC2C5] hover:text-[#F1FDFC] transition duration-300 block text-center lg:inline-block">
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

const MarubeLabs = () => {
  const [isSticky, setIsSticky] = useState(false);

  const openCalendly = () => {
    window.open('https://calendly.com/rudy-marubelabs/30min', '_blank');
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Router>
      <div className="font-sans bg-[#F1FDFC] smooth-scroll overscroll-none no-bounce">
        <Navbar isSticky={isSticky} openCalendly={openCalendly} scrollToSection={scrollToSection} />

        <Routes>
          <Route path="/" element={<MainContent openCalendly={openCalendly} defaultOptions={defaultOptions} scrollToSection={scrollToSection} />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>

        {/* Footer */}
        <footer className="bg-[#564EA5] text-white py-12">
          <div className="container mx-auto px-4 md:px-8 lg:px-12 max-w-7xl text-center">
            <p>&copy; 2024 Marube Labs. Empowering businesses with micro SaaS solutions and tech education.</p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 mt-6">
              <button 
                onClick={openCalendly}
                className="bg-[#5CC2C5] text-[#F1FDFC] px-6 py-3 rounded-md hover:bg-[#F1FDFC] hover:text-[#5CC2C5] flex items-center justify-center"
              >
                Book Your Free 30-Minute Consultation <Calendar className="ml-2" size={20} />
              </button>
              <Link to="/contact" className="bg-[#F1FDFC] text-[#5CC2C5] px-6 py-3 rounded-md border-2 border-[#5CC2C5] hover:bg-[#5CC2C5] hover:text-[#F1FDFC] transition duration-300">
                Contact Us
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
};

const AnimatedAurora = () => {
  return (
    <motion.div
      className="absolute inset-0 z-0 overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }} // Faster fade-in for the whole aurora
    >
      {/* First Motion Div: Lighter Aurora with increased animation */}
      <motion.div
        className="absolute inset-0"
        style={{
          background: 'radial-gradient(circle at top center, #6FE5EA 5%, #F1FDFC 50%, #F1FDFC 100%)',
          filter: 'blur(40px)',
        }}
        animate={{
          scale: [1, 1.5, 1],  // Increased scaling for more noticeable growth
          opacity: [0.4, 1, 0.4],  // Stronger opacity change
        }}
        transition={{
          duration: 3,  // Faster animation
          repeat: Infinity,
          repeatType: "reverse",
        }}
      />
      
      {/* Second Motion Div: Darker Aurora with increased movement and animation */}
      <motion.div
        className="absolute inset-0"
        style={{
          background: 'radial-gradient(circle at 125% 125%, #6FE5EA 0%, transparent 20%)',
          filter: 'blur(40px)',
        }}
        animate={{
          scale: [1, 1.3, 1],  // Increased scaling for a smoother growth effect
          opacity: [0.2, 0.8, 0.2],  // More noticeable opacity change
          x: [0, 400, 0],  // Increased horizontal movement (400px)
          y: [0, 500, 0],  // Increased vertical movement (500px)
        }}
        transition={{
          duration: 8, // Faster overall movement
          repeat: Infinity,
          repeatType: "reverse",
        }}
      />
    </motion.div>
  );
};

const MainContent = ({ openCalendly, defaultOptions, scrollToSection }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1);
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  }, [location]);

  return (
    <>
      {/* Hero Section */}
      <section className="relative pt-28 pb-20 overflow-hidden">
        <AnimatedAurora />
        <div className="container mx-auto px-4 md:px-8 lg:px-12 max-w-7xl relative z-10">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="lg:w-1/2 text-center lg:text-left mb-10 lg:mb-0">
              <h1 className="text-6xl font-bold mb-6 text-[#564EA5]">
                We Empower Businesses with<br />
                <span className="text-[#5cc2c5]">Micro SaaS Solutions</span>
              </h1>
              <p className="text-xl text-[#564EA5] mb-8">
                Marube Labs specializes in building micro SaaS products and providing expert consultation and education for non-technical entrepreneurs who want to build their own saas products.
              </p>
              <div className="flex flex-col sm:flex-row justify-center lg:justify-start space-y-4 sm:space-y-0 sm:space-x-4">
                <button 
                  onClick={openCalendly}
                  className="bg-[#5CC2C5] text-[#F1FDFC] px-6 py-3 rounded-md hover:bg-[#F1FDFC] hover:text-[#5CC2C5] flex items-center justify-center"
                >
                  Book Free Consultation <Calendar className="ml-2" size={20} />
                </button>
                <Link to="/contact" className="bg-[#F1FDFC] text-[#5CC2C5] px-6 py-3 rounded-md border-2 border-[#5CC2C5] hover:bg-[#5CC2C5] hover:text-[#F1FDFC] transition duration-300 text-center">
                  Contact Us
                </Link>
              </div>
            </div>
            <div className="lg:w-1/2">
              <Lottie options={defaultOptions}
                      height={400}
                      width={400}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-20 bg-[#F1FDFC]">
        <div className="container mx-auto px-4 md:px-8 lg:px-12 max-w-7xl">
          <h2 className="text-4xl font-bold text-center mb-6 text-[#564EA5]">Our Services</h2>
          <p className="text-xl text-[#564EA5] text-center mb-12">
            Expert Services to Bring Your SaaS Vision to Life
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            <FeatureCard
              icon={<Lightbulb size={24} className="text-[#5CC2C5]" />}
              title="Consultation & Strategy"
              description="We guide non-technical entrepreneurs through the process of turning their ideas into a functional SaaS product. Our strategy sessions provide insights on product validation, market fit, and feature prioritization."
              scrollToSection={scrollToSection}
            />
            <FeatureCard
              icon={<Code size={24} className="text-[#5CC2C5]" />}
              title="Micro SaaS Development"
              description="From idea to execution, we specialize in building micro SaaS products—lean, scalable, and focused solutions that can be brought to market quickly. Our team handles all technical aspects, so you can focus on growing your business."
              scrollToSection={scrollToSection}
            />
            <FeatureCard
              icon={<Users size={24} className="text-[#5CC2C5]" />}
              title="SaaS MVP Development"
              description="We develop Minimum Viable Products (MVP) that allow you to launch, test, and iterate quickly. A faster path to market means faster feedback and growth."
              scrollToSection={scrollToSection}
            />
            <FeatureCard
              icon={<BookOpen size={24} className="text-[#5CC2C5]" />}
              title="Custom SaaS Solutions"
              description="Have a more complex idea? We provide custom SaaS development solutions to fit your specific business needs."
              scrollToSection={scrollToSection}
            />
          </div>
        </div>
      </section>

      {/* Products Section */}
      <section id="products" className="bg-white py-20">
        <div className="container mx-auto px-4 md:px-8 lg:px-12 max-w-7xl">
          <h2 className="text-4xl font-bold text-center mb-12 text-[#564EA5]">Our Products</h2>
          <p className="text-xl text-[#564EA5] text-center mb-12 mx-auto max-w-3xl">
            At Marube Labs, we are constantly creating and launching new micro SaaS products<br />
            designed to solve real-world problems. Explore our portfolio of current SaaS offerings:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              icon={<Users size={24} className="text-[#5CC2C5]" />}
              title="SaaS CRM"
              description="A powerful customer relationship management system for small businesses."
              scrollToSection={scrollToSection}
            />
            <FeatureCard
              icon={<BookOpen size={24} className="text-[#5CC2C5]" />}
              title="Inventory Management"
              description="Keep track of inventory and orders in real-time with ease."
              scrollToSection={scrollToSection}
            />
            <FeatureCard
              icon={<DollarSign size={24} className="text-[#5CC2C5]" />}
              title="Automated Invoicing Tools"
              description="Simplify your billing and invoicing processes."
              scrollToSection={scrollToSection}
            />
          </div>
        </div>
      </section>

      {/* Education Section */}
      <section id="education" className="py-20 bg-[#F1FDFC]">
        <div className="container mx-auto px-4 md:px-8 lg:px-12 max-w-7xl">
          <h2 className="text-4xl font-bold text-center mb-6 text-[#564EA5]">Education</h2>
          <h3 className="text-2xl font-semibold text-center mb-12 text-[#897EFC]">Build Your Own Micro-SaaS While You Learn</h3>
          <p className="text-xl text-[#564EA5] text-center mb-12 mx-auto max-w-3xl">
            At Marube Labs, we don't just build products; we empower entrepreneurs to be part of the process.
            Our educational program allows you to learn how to build your own<br /> Micro SaaS while gaining hands-on experience.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              icon={<Users size={24} className="text-[#5CC2C5]" />}
              title="Tailored for Non-Technical Entrepreneurs"
              description="Learn the fundamentals of building Micro SaaS solutions with expert guidance."
              scrollToSection={scrollToSection}
            />
            <FeatureCard
              icon={<ArrowRight size={24} className="text-[#5CC2C5]" />}
              title="Step-by-Step Process"
              description="We walk you through each step from idea validation to launching your product."
              scrollToSection={scrollToSection}
            />
            <FeatureCard
              icon={<Lightbulb size={24} className="text-[#5CC2C5]" />}
              title="Active Participation"
              description="You'll be involved in building your product, giving you the confidence to manage and scale it post-launch."
              scrollToSection={scrollToSection}
            />
          </div>
        </div>
      </section>

      {/* Education Package Section */}
      <section id="education-package" className="py-20 bg-white">
        <div className="container mx-auto px-4 md:px-8 lg:px-12 max-w-7xl">
          <h2 className="text-4xl font-bold text-center mb-6 text-[#564EA5]">Education Packages</h2>
          <h3 className="text-2xl font-semibold text-center mb-12 text-[#897EFC]">Empower Yourself with SaaS Knowledge</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <PricingCard
              title="Online Courses & Webinars"
              price="$49"
              description="Access to all self-paced courses and live webinars, including updates on new SaaS trends, technical tips, and strategies."
              features={[
                "Self-paced courses",
                "Live webinars",
                "SaaS trend updates",
                "Technical tips and strategies"
              ]}
            />
            <PricingCard
              title="SaaS Building Masterclass"
              price="$199"
              description="Full access to our comprehensive masterclass series, with new lessons added regularly, guiding you from idea to launch with in-depth training."
              features={[
                "Comprehensive masterclass series",
                "Regular new lessons",
                "Idea to launch guidance",
                "In-depth SaaS training"
              ]}
            />
            <PricingCard
              title="One-on-One Mentorship"
              price="$399"
              description="Includes four 1-hour mentorship sessions per month, offering personalized advice and guidance throughout your SaaS journey. Perfect for entrepreneurs looking for hands-on support."
              features={[
                "Four 1-hour sessions per month",
                "Personalized advice",
                "Hands-on support",
                "Tailored SaaS guidance"
              ]}
            />
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="py-20 bg-[#F1FDFC]">
        <div className="container mx-auto px-4 md:px-8 lg:px-12 max-w-7xl">
          <h2 className="text-4xl font-bold text-center mb-6 text-[#564EA5]">Pricing</h2>
          <h3 className="text-2xl font-semibold text-center mb-12 text-[#897EFC]">Flexible Monthly Plans That Fit Your SaaS Journey</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <PricingCard
              title="Consultation & Strategy"
              price="$299"
              description="Includes two 1-hour strategy sessions each month to help guide your SaaS development, idea validation, and market positioning."
              features={[
                "Two 1-hour strategy sessions per month",
                "Expert guidance and feedback",
                "Idea validation support",
                "Market positioning advice"
              ]}
            />
            <PricingCard
              title="Micro SaaS Development"
              price="$999"
              description="For complete micro SaaS product development. With the use of AI, we deliver faster and more efficiently, giving you significant savings compared to traditional development models."
              features={[
                "Complete product development",
                "AI-powered efficiency",
                "Faster delivery",
                "Cost-effective solution"
              ]}
            />
            <PricingCard
              title="SaaS MVP Package"
              price="$1,499"
              description="This plan includes the design, development, and launch of your Minimum Viable Product (MVP). Our AI-enhanced process allows us to rapidly iterate, getting your product to market quicker, while keeping costs lower."
              features={[
                "MVP design and development",
                "Rapid iteration with AI",
                "Faster time-to-market",
                "Cost-effective MVP launch"
              ]}
            />
            <PricingCard
              title="Custom Solutions"
              price="$2,499"
              description="For more complex or larger-scale SaaS projects, pricing is customized based on specific needs. The AI-powered development process ensures reduced timelines and cost savings for you."
              features={[
                "Tailored to your specific needs",
                "AI-powered development",
                "Reduced timelines",
                "Customized pricing"
              ]}
            />
          </div>
        </div>
      </section>

      {/* How We Work Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4 md:px-8 lg:px-12 max-w-7xl">
          <h2 className="text-4xl font-bold text-center mb-12 text-[#564EA5]">How We Work</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div>
              <h3 className="text-2xl font-bold mb-4 text-[#897EFC]">Tailored Solutions for Your Business</h3>
              <p className="text-[#564EA5] mb-6">
                We work closely with you to understand your needs and deliver custom micro SaaS solutions.
              </p>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <div className="bg-white p-2 rounded-md mr-4">
                    <div className="w-6 h-6 bg-[#5CC2C5] rounded-md"></div>
                  </div>
                  <div>
                    <h4 className="font-bold text-[#897EFC]">Agile Development</h4>
                    <p className="text-[#564EA5]">Our iterative approach ensures your product evolves with your business needs and market demands.</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="bg-white p-2 rounded-md mr-4">
                    <div className="w-6 h-6 bg-[#5CC2C5] rounded-md"></div>
                  </div>
                  <div>
                    <h4 className="font-bold text-[#897EFC]">Education-First Approach</h4>
                    <p className="text-[#564EA5]">We don't just build products; we empower you with the knowledge to understand and grow your tech business.</p>
                  </div>
                </li>
              </ul>
              <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-6">
                <button 
                  onClick={openCalendly}
                  className="bg-[#5CC2C5] text-[#F1FDFC] px-6 py-3 rounded-md hover:bg-[#F1FDFC] hover:text-[#5CC2C5] flex items-center justify-center"
                >
                  Schedule Your Free Consultation <Calendar className="ml-2" size={20} />
                </button>
                <Link to="/contact" className="bg-[#F1FDFC] text-[#5CC2C5] px-6 py-3 rounded-md border-2 border-[#5CC2C5] hover:bg-[#5CC2C5] hover:text-[#F1FDFC] transition duration-300">
                  Contact Us
                </Link>
              </div>
            </div>
            <div className="bg-white p-8 rounded-lg glow-effect">
              <img src="/api/placeholder/600/400" alt="Marube Labs Workflow" className="w-full rounded-md" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const FeatureCard = ({ icon, title, description, scrollToSection }) => {
  return (
    <div className="bg-white p-6 rounded-lg glow-effect">
      {icon}
      <h3 className="text-xl font-bold mt-4 mb-2 text-[#897EFC]">{title}</h3>
      <p className="text-[#564EA5]">{description}</p>
      <button 
        onClick={() => scrollToSection('contact')} 
        className="text-[#5CC2C5] font-semibold mt-4 hover:text-[#564EA5]"
      >
        Learn More
      </button>
    </div>
  );
};

const PricingCard = ({ title, price, description, features }) => {
  return (
    <div className="bg-white p-6 rounded-lg glow-effect flex flex-col h-full">
      <h3 className="text-2xl font-bold mb-2 text-[#897EFC]">{title}</h3>
      <div className="text-3xl font-bold mb-4 text-[#564EA5]">{price}<span className="text-lg font-normal"> /month</span></div>
      <p className="text-[#564EA5] mb-6 flex-grow">{description}</p>
      <ul className="mb-6">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center mb-2">
            <ArrowRight size={16} className="text-[#5CC2C5] mr-2 flex-shrink-0" />
            <span className="text-[#564EA5]">{feature}</span>
          </li>
        ))}
      </ul>
      <button className="bg-[#5CC2C5] text-white px-4 py-2 rounded-md hover:bg-[#564EA5] transition duration-300 mt-auto">
        Get Started
      </button>
    </div>
  );
};

export default MarubeLabs;